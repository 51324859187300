exports.components = {
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-pricing-faq-jsx": () => import("./../../../src/pages/pricing-faq.jsx" /* webpackChunkName: "component---src-pages-pricing-faq-jsx" */),
  "component---src-pages-sign-up-complete-jsx": () => import("./../../../src/pages/sign-up-complete.jsx" /* webpackChunkName: "component---src-pages-sign-up-complete-jsx" */),
  "component---src-pages-sign-up-email-jsx": () => import("./../../../src/pages/sign-up-email.jsx" /* webpackChunkName: "component---src-pages-sign-up-email-jsx" */),
  "component---src-pages-verify-email-jsx": () => import("./../../../src/pages/verify-email.jsx" /* webpackChunkName: "component---src-pages-verify-email-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-doc-page-jsx": () => import("./../../../src/templates/doc-page.jsx" /* webpackChunkName: "component---src-templates-doc-page-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-static-page-jsx": () => import("./../../../src/templates/static-page.jsx" /* webpackChunkName: "component---src-templates-static-page-jsx" */)
}

